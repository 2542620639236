exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admin-close-settlement-jsx": () => import("./../../../src/pages/admin/closeSettlement.jsx" /* webpackChunkName: "component---src-pages-admin-close-settlement-jsx" */),
  "component---src-pages-admin-export-csv-jsx": () => import("./../../../src/pages/admin/exportCsv.jsx" /* webpackChunkName: "component---src-pages-admin-export-csv-jsx" */),
  "component---src-pages-admin-log-switch-jsx": () => import("./../../../src/pages/admin/logSwitch.jsx" /* webpackChunkName: "component---src-pages-admin-log-switch-jsx" */),
  "component---src-pages-api-requests-[code]-jsx": () => import("./../../../src/pages/api-requests/[code].jsx" /* webpackChunkName: "component---src-pages-api-requests-[code]-jsx" */),
  "component---src-pages-api-requests-index-jsx": () => import("./../../../src/pages/api-requests/index.jsx" /* webpackChunkName: "component---src-pages-api-requests-index-jsx" */),
  "component---src-pages-auth-[code]-index-jsx": () => import("./../../../src/pages/auth/[code]/index.jsx" /* webpackChunkName: "component---src-pages-auth-[code]-index-jsx" */),
  "component---src-pages-callback-facebook-jsx": () => import("./../../../src/pages/callback/facebook.jsx" /* webpackChunkName: "component---src-pages-callback-facebook-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-email-resend-jsx": () => import("./../../../src/pages/email/resend.jsx" /* webpackChunkName: "component---src-pages-email-resend-jsx" */),
  "component---src-pages-email-verification-[code]-index-jsx": () => import("./../../../src/pages/email/verification/[code]/index.jsx" /* webpackChunkName: "component---src-pages-email-verification-[code]-index-jsx" */),
  "component---src-pages-error-jsx": () => import("./../../../src/pages/error.jsx" /* webpackChunkName: "component---src-pages-error-jsx" */),
  "component---src-pages-firmy-[code]-index-jsx": () => import("./../../../src/pages/firmy/[code]/index.jsx" /* webpackChunkName: "component---src-pages-firmy-[code]-index-jsx" */),
  "component---src-pages-firmy-index-jsx": () => import("./../../../src/pages/firmy/index.jsx" /* webpackChunkName: "component---src-pages-firmy-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kalkulator-odsetek-ustawowych-jsx": () => import("./../../../src/pages/kalkulator-odsetek-ustawowych.jsx" /* webpackChunkName: "component---src-pages-kalkulator-odsetek-ustawowych-jsx" */),
  "component---src-pages-notifications-[code]-index-jsx": () => import("./../../../src/pages/notifications/[code]/index.jsx" /* webpackChunkName: "component---src-pages-notifications-[code]-index-jsx" */),
  "component---src-pages-order-renewals-[code]-jsx": () => import("./../../../src/pages/order-renewals/[code].jsx" /* webpackChunkName: "component---src-pages-order-renewals-[code]-jsx" */),
  "component---src-pages-orders-[code]-index-jsx": () => import("./../../../src/pages/orders/[code]/index.jsx" /* webpackChunkName: "component---src-pages-orders-[code]-index-jsx" */),
  "component---src-pages-orders-index-jsx": () => import("./../../../src/pages/orders/index.jsx" /* webpackChunkName: "component---src-pages-orders-index-jsx" */),
  "component---src-pages-password-reset-[code]-index-jsx": () => import("./../../../src/pages/password/reset/[code]/index.jsx" /* webpackChunkName: "component---src-pages-password-reset-[code]-index-jsx" */),
  "component---src-pages-pay-[code]-index-jsx": () => import("./../../../src/pages/pay/[code]/index.jsx" /* webpackChunkName: "component---src-pages-pay-[code]-index-jsx" */),
  "component---src-pages-payers-[code]-jsx": () => import("./../../../src/pages/payers/[code].jsx" /* webpackChunkName: "component---src-pages-payers-[code]-jsx" */),
  "component---src-pages-payers-index-jsx": () => import("./../../../src/pages/payers/index.jsx" /* webpackChunkName: "component---src-pages-payers-index-jsx" */),
  "component---src-pages-payment-jsx": () => import("./../../../src/pages/payment.jsx" /* webpackChunkName: "component---src-pages-payment-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-regulamin-jsx": () => import("./../../../src/pages/regulamin.jsx" /* webpackChunkName: "component---src-pages-regulamin-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-user-accounts-jsx": () => import("./../../../src/pages/user/accounts.jsx" /* webpackChunkName: "component---src-pages-user-accounts-jsx" */),
  "component---src-pages-user-api-keys-jsx": () => import("./../../../src/pages/user/api-keys.jsx" /* webpackChunkName: "component---src-pages-user-api-keys-jsx" */),
  "component---src-pages-user-identity-jsx": () => import("./../../../src/pages/user/identity.jsx" /* webpackChunkName: "component---src-pages-user-identity-jsx" */),
  "component---src-pages-user-invoices-[code]-index-jsx": () => import("./../../../src/pages/user/invoices/[code]/index.jsx" /* webpackChunkName: "component---src-pages-user-invoices-[code]-index-jsx" */),
  "component---src-pages-user-invoices-index-jsx": () => import("./../../../src/pages/user/invoices/index.jsx" /* webpackChunkName: "component---src-pages-user-invoices-index-jsx" */),
  "component---src-pages-user-register-jsx": () => import("./../../../src/pages/user/register.jsx" /* webpackChunkName: "component---src-pages-user-register-jsx" */),
  "component---src-pages-user-reset-password-jsx": () => import("./../../../src/pages/user/reset-password.jsx" /* webpackChunkName: "component---src-pages-user-reset-password-jsx" */),
  "component---src-pages-user-settings-jsx": () => import("./../../../src/pages/user/settings.jsx" /* webpackChunkName: "component---src-pages-user-settings-jsx" */),
  "component---src-pages-user-settlements-[code]-index-jsx": () => import("./../../../src/pages/user/settlements/[code]/index.jsx" /* webpackChunkName: "component---src-pages-user-settlements-[code]-index-jsx" */),
  "component---src-pages-user-settlements-index-jsx": () => import("./../../../src/pages/user/settlements/index.jsx" /* webpackChunkName: "component---src-pages-user-settlements-index-jsx" */),
  "component---src-pages-user-sign-in-jsx": () => import("./../../../src/pages/user/sign-in.jsx" /* webpackChunkName: "component---src-pages-user-sign-in-jsx" */),
  "component---src-templates-company-card-jsx": () => import("./../../../src/templates/companyCard.jsx" /* webpackChunkName: "component---src-templates-company-card-jsx" */)
}

