import { createTheme } from "@mui/material";

const theme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1920,
  //   },
  // },
  typography: {
    fontFamily: 'Nunito',
    fontWeightRegular: '300',
    button: {
      textTransform: "none"
    },
  },
  palette: {
    primary: {
      main: '#f59e0b',
      light: '#ffcf4b',
      dark: '#bd6f00',
    },
    secondary: {
      main: '#D93737',
      contrastText: '#fff',
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;