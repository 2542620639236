import {combineReducers} from 'redux';
import * as constants from '../constants';

export function generalError(state = null, action) {
  return singleSwitchReducer(state, action, constants.GENERAL_ERROR);
}

export function loginRequired(state = null, action) {
  return singleSwitchReducer(state, action, constants.LOGIN_REQUIRED);
}

export function login(state = null, action) {
  return singleSwitchReducer(state, action, constants.LOGIN);
}

export function register(state = null, action) {
  return singleSwitchReducer(state, action, constants.REGISTER);
}

export function createOrder(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_ORDER);
}

export function updateOrderDescription(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_ORDER_DESCRIPTION);
}

export function createDebtor(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_DEBTOR);
}

export function createNotificationsPlan(state = null, action) {
  return singleSwitchReducer(
    state,
    action,
    constants.CREATE_NOTIFICATIONS_PLAN
  );
}

export function getPlanProposition(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PLAN_PROPOSITION);
}

export function getNotificationPreview(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_NOTIFICATION_PREVIEW);
}

export function getOrder(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDER);
}

export function getSimpleOrder(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SIMPLE_ORDER);
}

export function getOrders(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDERS);
}

export function getOrdersFee(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDERS_FEE);
}

export function checkUserEmail(state = null, action) {
  return singleSwitchReducer(state, action, constants.CHECK_USER_EMAIL);
}

export function checkOrderLimits(state = null, action) {
  return singleSwitchReducer(state, action, constants.CHECK_ORDER_LIMITS);
}

export function getInvoices(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_INVOICES);
}

export function getInvoice(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_INVOICE);
}

export function getOrdersForInvoice(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDERS_FOR_INVOICE);
}

export function getSettlements(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SETTLEMENTS);
}

export function getSettlement(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SETTLEMENT);
}

export function getSettlementReport(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_SETTLEMENT_REPORT);
}

export function closeSettlement(state = null, action) {
  return singleSwitchReducer(state, action, constants.CLOSE_SETTLEMENT);
}

export function getOrderEventsHistory(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_ORDER_EVENTS_HISTORY);
}

export function verifyUserEmail(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_EMAIL_VERIFICATION);
}
export function facebookCallback(state = null, action) {
  return singleSwitchReducer(state, action, constants.FACEBOOK_CALLBACK);
}

export function facebookAction(state = null, action) {
  return singleSwitchReducer(state, action, constants.FACEBOOK_ACTION);
}

export function resendVerificationEmail(state = null, action) {
  return singleSwitchReducer(
    state,
    action,
    constants.USER_EMAIL_VERIFICATION_RESEND
  );
}

export function registerStep(state = null, action) {
  switch (action.type) {
    case constants.SET_REGISTER_STEP:
      return action.payload;
    default:
      break;
  }
  return state;
}

export function snackbarNotification(state = null, action) {
  switch (action.type) {
    case constants.SET_SNACKBAR_NOTIFICATION:
      let notification = action.payload;
      if(notification.severity && notification.message)
        return action.payload;
      break;
    case constants.SET_ERROR_SNACKBAR_NOTIFICATION:
        return {
          message: action.payload,
          severity: 'error'
        };
    default:
      break;
  }
  return state;
}

export function toggleDrawer(state = null, action) {
  switch (action.type) {
    case constants.TOGGLE_DRAWER:
      return !action.payload;
    default:
      break;
  }
  return state;
}

export function toggleLogin(state = null, action) {
  switch (action.type) {
    case constants.TOGGLE_LOGIN:
      return !action.payload;
    default:
      break;
  }
  return state;
}

export function get2FAAction(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_2FA_ACTION);
}

export function handle2FADecision(state = null, action) {
  return singleSwitchReducer(state, action, constants.EMAIL_2FA_DECISION);
}

export function get2FADevices(state = null, action) {
  return singleSwitchReducer(state, action, constants.USER_2FA_DEVICES);
}

export function getUser(state = null, action) {
  switch (action.type) {
    case constants.GET_USER:
    case constants.GET_USER_SILENT:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function logSwitch(state = null, action) {
  return singleSwitchReducer(state, action, constants.LOG_SWITCH);
}

export function getPartner(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PARTNER);
}

export function createPartner(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_PARTNER);
}

export function getPartnerAccounts(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PARTNER_ACCOUNTS);
}

export function setPartnerAccountName(state = null, action) {
  return singleSwitchReducer(state, action, constants.SET_PARTNER_ACCOUNT_NAME);
}

export function updatePartnerAccounts(state = null, action) {
  return singleSwitchReducer(state, action, constants.UPDATE_PARTNER_ACCOUNTS);
}

export function partnerBankAccountVerificationOrders(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_PARTNER_ACCOUNT_VERIFICATION_ORDERS);
}

export function setPartnerAccountAsVat(state = null, action) {
  return singleSwitchReducer(
    state,
    action,
    constants.SET_PARTNER_ACCOUNT_AS_VAT
  );
}

export function sendPassResetEmail(state = null, action) {
  return singleSwitchReducer(state, action, constants.PASSWORD_RESET_SEND);
}

export function checkPassReset(state = null, action) {
  return singleSwitchReducer(state, action, constants.PASSWORD_RESET_CHECK);
}

export function resetPassword(state = null, action) {
  return singleSwitchReducer(state, action, constants.PASSWORD_RESET);
}

export function cancelOrder(state = null, action) {
  return singleSwitchReducer(state, action, constants.CANCEL_ORDER);
}

export function markOrderAsPaidExternally(state = null, action) {
  return singleSwitchReducer(state, action, constants.MARK_ORDER_AS_PAID_EXTERNALLY);
}

export function companyCards(state = null, action) {
  return singleSwitchReducer(state, action, constants.COMPANY_CARDS);
}

export function companyCard(state = null, action) {
  return singleSwitchReducer(state, action, constants.COMPANY_CARD);
}

export function getPayers(state = null, action) {
  return singleSwitchReducer(state, action, constants.PAYERS_GET);
}

export function findPayer(state = null, action) {
  return singleSwitchReducer(state, action, constants.PAYER_GET);
}

export function createPayer(state = null, action) {
  return singleSwitchReducer(state, action, constants.PAYER_CREATE);
}

export function updatePayer(state = null, action) {
  return singleSwitchReducer(state, action, constants.PAYER_UPDATE);
}

export function getOrdersByPayer(state = null, action) {
  return singleSwitchReducer(state, action, constants.PAYER_GET_ORDERS);
}

export function getOrderRenewal(state = null, action) {
  return singleSwitchReducer(state, action, constants.ORDER_RENEWAL_GET);
}

export function getOrdersForOrderRenewal(state = null, action) {
  return singleSwitchReducer(state, action, constants.ORDER_RENEWAL_GET_ORDERS);
}

export function getScheduleForOrderRenewal(state = null, action) {
  return singleSwitchReducer(state, action, constants.ORDER_RENEWAL_GET_SCHEDULE);
}

export function getScheduleForAllRenewals(state = null, action) {
  return singleSwitchReducer(state, action, constants.ORDER_RENEWAL_GET_ALL_SCHEDULES);
}

export function getTags(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_TAGS);
}

export function getLiabilityInterestCalculation(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_LIABILITY_INTEREST_CALCULATION);
}

export function exportWithdrawalCsv(state = null, action) {
  switch (action.type) {
    case constants.EXPORT_WITHDRAWAL_CSV:
      if (action.payload.status >= 400) return action.payload.data;
      const contentDisposition = action.payload.headers['content-disposition'];
      const filename = contentDisposition
        .split('filename=')[1]
        .replaceAll('"', '');
      return {
        content: action.payload.data,
        filename,
      };
    default:
      break;
  }

  return state;
}

export function createApiKeys(state = null, action) {
  return singleSwitchReducer(state, action, constants.CREATE_APIKEYS);
}

export function getApiKeys(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_APIKEYS);
}

export function disableApiKeys(state = null, action) {
  return singleSwitchReducer(state, action, constants.DISABLE_APIKEYS);
}

export function cancel2FADialog(state = false, action) {
  switch (action.type) {
    case constants.CANCEL_2FA_DIALOG:
      return !state;
    default:
      break;
  }
  return state;
}

export function getAPIRequest(state = null, action) {
  return singleSwitchReducer(state, action, constants.GET_API_REQUEST);
}


export function findAPIRequests(state = null, action) {
  return singleSwitchReducer(state, action, constants.FIND_API_REQUESTS);
}

const rootReducer = combineReducers({
  generalError,
  toggleDrawer,
  toggleLogin,
  login,
  register,
  createOrder,
  getOrder,
  getOrders,
  getOrdersFee,
  createDebtor,
  createNotificationsPlan,
  getPlanProposition,
  getNotificationPreview,
  checkUserEmail,
  checkOrderLimits,
  registerStep,
  snackbarNotification,
  getInvoices,
  getInvoice,
  getOrdersForInvoice,
  closeSettlement,
  exportWithdrawalCsv,
  getSettlements,
  getSettlement,
  getSettlementReport,
  getOrderEventsHistory,
  get2FAAction,
  get2FADevices,
  handle2FADecision,
  getUser,
  verifyUserEmail,
  resendVerificationEmail,
  logSwitch,
  getPartner,
  createPartner,
  getPartnerAccounts,
  setPartnerAccountName,
  updatePartnerAccounts,
  partnerBankAccountVerificationOrders,
  setPartnerAccountAsVat,
  sendPassResetEmail,
  checkPassReset,
  resetPassword,
  loginRequired,
  getSimpleOrder,
  cancelOrder,
  markOrderAsPaidExternally,
  updateOrderDescription,
  facebookCallback,
  facebookAction,
  companyCards,
  companyCard,
  getPayers,
  findPayer,
  createPayer,
  updatePayer,
  getOrdersByPayer,
  getOrderRenewal,
  getOrdersForOrderRenewal,
  getScheduleForOrderRenewal,
  getScheduleForAllRenewals,
  getTags,
  getLiabilityInterestCalculation,
  createApiKeys: createApiKeys,
  getApiKeys: getApiKeys,
  disableApiKeys: disableApiKeys,
  cancel2FADialog,
  getAPIRequest,
  findAPIRequests,
});

export default rootReducer;

function singleSwitchReducer(state, action, type) {
  switch (action.type) {
    case type:
      return action.payload.data;
    default:
      break;
  }
  return state;
}
