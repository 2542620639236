export const SHOW_FACEBOOK = process.env.GATSBY_SHOW_FACEBOOK ?? false;
export const CONTACT_CALENDLY = process.env.GATSBY_CONTACT_CALENDLY ?? false;
export const GENERAL_ERROR = 'GENERAL_ERROR';
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const RESET = 'RESET';
export const SET = 'SET';
export const SET_REGISTER_STEP = 'SET_REGISTER_STEP';
export const SET_SNACKBAR_NOTIFICATION = 'SET_SNACKBAR_NOTIFICATION';
export const SET_ERROR_SNACKBAR_NOTIFICATION = 'SET_ERROR_SNACKBAR_NOTIFICATION';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_DEBTOR = 'CREATE_DEBTOR';
export const CREATE_NOTIFICATIONS_PLAN = 'CREATE_NOTIFICATIONS_PLAN';
export const GET_PLAN_PROPOSITION = 'GET_PLAN_PROPOSITION';
export const GET_NOTIFICATION_PREVIEW = 'GET_NOTIFICATION_PREVIEW';
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_FEE = 'GET_ORDERS_FEE';
export const CHECK_USER_EMAIL = 'CHECK_USER_EMAIL';
export const CHECK_ORDER_LIMITS = 'CHECK_ORDER_LIMITS';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICE = 'GET_INVOICE';
export const GET_ORDERS_FOR_INVOICE = 'GET_ORDERS_FOR_INVOICE';
export const GET_SETTLEMENTS = 'GET_SETTLEMENTS';
export const GET_SETTLEMENT = 'GET_SETTLEMENT';
export const GET_SETTLEMENT_REPORT = 'GET_SETTLEMENT_REPORT';
export const GET_ORDER_EVENTS_HISTORY = 'GET_ORDER_EVENTS_HISTORY';
export const GET_2FA_ACTION = 'GET_2FA_ACTION';
export const USER_2FA_DEVICES = 'USER_2FA_DEVICES';
export const EMAIL_2FA_DECISION = 'EMAIL_2FA_DECISION';
export const GET_USER = 'GET_USER';
export const GET_PARTNER = 'GET_PARTNER';
export const CREATE_PARTNER = 'CREATE_PARTNER';
export const GET_PARTNER_ACCOUNTS = 'GET_PARTNER_ACCOUNTS';
export const GET_PARTNER_ACCOUNT_VERIFICATION_ORDERS = 'GET_PARTNER_ACCOUNT_VERIFICATION_ORDERS';
export const SET_PARTNER_ACCOUNT_NAME = 'SET_PARTNER_ACCOUNT_NAME';
export const UPDATE_PARTNER_ACCOUNTS = 'UPDATE_PARTNER_ACCOUNTS';
export const SET_PARTNER_ACCOUNT_AS_VAT = 'SET_PARTNER_ACCOUNT_AS_VAT';
export const USER_EMAIL_VERIFICATION = 'USER_EMAIL_VERIFICATION';
export const USER_EMAIL_VERIFICATION_RESEND = 'USER_EMAIL_VERIFICATION_RESEND';
export const PASSWORD_RESET_SEND = 'PASSWORD_RESET_SEND';
export const PASSWORD_RESET_CHECK = 'PASSWORD_RESET_CHECK';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const GET_SIMPLE_ORDER = 'GET_SIMPLE_ORDER';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const MARK_ORDER_AS_PAID_EXTERNALLY = 'MARK_ORDER_AS_PAID_EXTERNALLY';
export const UPDATE_ORDER_DESCRIPTION = 'UPDATE_ORDER_DESCRIPTION';
export const FACEBOOK_CALLBACK = 'FACEBOOK_CALLBACK';
export const FACEBOOK_ACTION = 'FACEBOOK_ACTION';
export const COMPANY_CARDS = 'COMPANY_CARDS';
export const COMPANY_CARD = 'COMPANY_CARD';
export const PAYERS_GET = 'PAYERS_GET';
export const PAYER_GET = 'PAYER_GET';
export const PAYER_CREATE = 'PAYER_CREATE';
export const PAYER_UPDATE = 'PAYER_UPDATE';
export const PAYER_GET_ORDERS = 'PAYER_GET_ORDERS';
export const ORDER_RENEWAL_GET = 'ORDER_RENEWAL_GET';
export const ORDER_RENEWAL_GET_ORDERS = 'ORDER_RENEWAL_GET_ORDERS';
export const ORDER_RENEWAL_GET_SCHEDULE = 'ORDER_RENEWAL_GET_SCHEDULE';
export const ORDER_RENEWAL_GET_ALL_SCHEDULES = 'ORDER_RENEWAL_GET_ALL_SCHEDULES';
export const GET_TAGS = 'GET_TAGS';
export const GET_LIABILITY_INTEREST_CALCULATION = 'GET_LIABILITY_INTEREST_CALCULATION';
export const CLOSE_SETTLEMENT = 'CLOSE_SETTLEMENT';
export const LOG_SWITCH = 'LOG_SWITCH';
export const EXPORT_WITHDRAWAL_CSV = 'EXPORT_WITHDRAWAL_CSV';
export const GET_USER_SILENT = 'GET_USER_SILENT';
export const CREATE_APIKEYS = 'CREATE_APIKEYS';
export const GET_APIKEYS = 'GET_APIKEYS';
export const DISABLE_APIKEYS = 'DISABLE_APIKEYS';
export const CANCEL_2FA_DIALOG = 'CANCEL_2FA_DIALOG';
export const GET_API_REQUEST = 'GET_API_REQUEST';
export const FIND_API_REQUESTS = 'FIND_API_REQUESTS';
