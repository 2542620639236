import React from 'react';
import store from './store';
import { Provider } from 'react-redux';
import './sass/styles.scss';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider }  from '@mui/x-date-pickers';
import pl from 'date-fns/locale/pl';
import './css/style.css';

const FullApp = ({ children }) => {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
        {children}
      </LocalizationProvider>
    </Provider>
  );
};

export default FullApp;
